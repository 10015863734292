/* Global */
a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.container {
  background: #fff;
  width: 1024px;
  min-height: 35.25rem;
}

@media (min-height: 1366px) {
  .container {
    min-height: 53.25rem;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

/* App */
main {
  background: #ebeff2;
  display: flex;
  justify-content: center;
}

/* Social Media Nav */
.social-media-nav {
  background: #1f1f1f;
}

.social-media-nav-list {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.social-media-nav-item {
  padding: 0.5rem;
}

.social-media-nav-link {
  color: #fff;
}

.social-media-nav-link:hover {
  color: #185493;
  transition: color 0.3s ease-in-out;
}

/* NavBar */
.nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  border-bottom: 0.15rem solid #28a745;
  position: sticky;
  top: 0;
}

.nav-bars-wrapper,
.nav-logo-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.nav-bars-wrapper {
  justify-content: flex-end;
}

.nav-list {
  display: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.fa-bars,
.fa-times {
  font-size: 2rem;
  color: #6c757d;
}

.active {
  color: #28a745;
  border-bottom: 0.25rem solid #28a745;
}

/* Mobile Nav */
.mobile-nav-list {
  padding: 0.5rem 0;
}

.mobile-nav-item {
  font-weight: bold;
  padding: 0.5rem 1rem;
}

/* Header */
.header {
  background-color: #1f1f1f;
  background-image: url('./images/header_img_2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 342px;
}

/* .header-home-img {
  visibility: hidden;
  width: 100%;
} */

.header-logo {
  position: absolute;
  top: 20rem;
}

@media (min-width: 1440px) {
  .header {
    background-size: contain;
  }
}

/* Home */
.content-header {
  color: #185493;
  padding: 1rem 2rem 0.5rem 2rem;
}

.home-text {
  padding: 0.5rem 2rem;
}

.home-text-list {
  list-style-type: disc;
  padding: 0.5rem 5rem;
}

/* Register */
.form {
  padding: 0.5rem;
}

.form-header {
  color: #185493;
  text-align: center;
}

.form-header,
.form-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.form-input {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 0.15rem solid #ced4da;
  outline: none;
  color: #495057;
  width: 14rem;
  -webkit-appearance: none;
}

.form-input:focus,
.form-textarea:focus {
  border: 0.15rem solid #28a745;
  transition: border 0.3s ease-in-out;
}

.form-textarea {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 0.15rem solid #ced4da;
  outline: none;
  color: #495057;
  width: 14rem;
  -webkit-appearance: none;
}

.form-submit,
.form-btn {
  background: #185493;
  color: #fff;
  border: 1px solid #185493;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  width: 16.25rem;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  -webkit-appearance: none;
}

.form-submit:hover,
.form-btn:hover {
  background: #fff;
  color: #185493;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.login-link:hover,
.register-link:hover {
  font-weight: bold;
  transition: font-weight 0.3s ease-in-out;
}

.return-link {
  background: #6c757d;
  color: #fff;
  border: 1px solid #6c757d;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  min-width: 14.25rem;
  max-width: 14.25rem;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.return-link:hover {
  background: #fff;
  color: #6c757d;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.month-input,
.kwh-input,
.gal-input {
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  border: 0.15rem solid #ced4da;
  border-radius: 0.25rem;
  color: #6c757d;
  outline: none;
  -webkit-appearance: none;
}

.month-input:focus,
.kwh-input:focus,
.gal-input:focus {
  border: 0.15rem solid #28a745;
  transition: border 0.3s ease-in-out;
}

.kwh-input,
.gal-input {
  max-width: 3.5rem;
}

@media (min-width: 768px) {
  .kwh-input {
    max-width: 5.75rem;
  }

  .month-input {
    width: 13.75rem;
  }
}

/* Claim */
.claim-top {
  padding: 0.5rem;
}

.claim-bottom {
  padding: 0.5rem;
}

.claim-top-left {
  display: flex;
  justify-content: center;
  color: #185493;
  padding: 0.5rem;
}

.claim-top-right {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.claim-new-home-btn {
  background: #28a745;
  color: #fff;
  padding: 0.5rem 1rem;
  border: 1px solid #28a745;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.claim-new-home-btn:hover {
  background: #fff;
  color: #28a745;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Registered Homes */
.registered-homes-container {
  display: flex;
  justify-content: center;
}

.registered-homes-grid {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5rem;
  margin: 0.5rem;
}

.registered-homes-grid:nth-child(odd) {
  background: #ebeff2;
}

.registered-homes-col {
  padding: 0.25rem;
}

.registered-homes-header {
  color: #185493;
}

.claim-btn {
  background: #185493;
  padding: 0.5rem 1rem;
  border: 1px solid #185493;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1rem;
}

.claim-btn:hover {
  background: transparent;
  color: #185493;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Footer */
footer {
  background: #1f1f1f;
  display: flex;
  justify-content: center;
  padding: 0.75rem;
}

.footer-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1024px;
}

.footer-nav-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footer-nav-list,
.copyright-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.footer-nav-item {
  padding: 0.25rem;
  font-weight: bold;
}

.footer-nav-link:hover {
  color: #185493;
  transition: color 0.3s ease-in-out;
}

.copyright-text {
  padding: 0.25rem;
}

/* Media */
@media (min-width: 768px) {
  /* NavBar */
  .nav {
    display: flex;
    justify-content: center;
    border-bottom: none;
  }

  .nav-bars-wrapper {
    display: none;
  }

  .nav-list {
    display: flex;
    align-items: center;
  }

  .nav-link {
    padding: 1.75rem;
    font-weight: bold;
    cursor: pointer;
  }

  .nav-link:hover {
    border-bottom: 0.25rem solid #28a745;
    color: #28a745;
    transition: color 0.3s ease-in-out;
  }

  /* Forms */
  .form-input,
  .form-textarea {
    width: 20rem;
  }

  .state-select {
    width: 22.25rem;
  }

  .form-submit,
  .form-btn {
    width: 22.25rem;
  }

  .return-link {
    min-width: 20.25rem;
    max-width: 20.25rem;
  }

  /* Claim */
  .claim-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
  }

  .claim-top-left {
    display: flex;
    justify-content: flex-start;
    padding: 0;
  }

  .claim-top-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }

  /* Registered Homes */
  .registered-homes-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .registered-homes-col:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    height: auto;
  }

  .footer-div {
    display: block;
  }
}

@media (max-width: 512px) {
  .style-btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .style-btn {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 414px) {
  .pipe {
    display: none;
  }

  .footer-nav-list {
    display: grid;
    grid-template-columns: 1fr;
  }
}

/* Preloader */
.preloader,
.preloader:hover {
  background-image: url('./images/loader.svg');
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: center;
}

/* Draftjs */
.editor {
  min-height: 5rem;
  margin: 0.5rem 2rem 1rem 2rem;
}

.style-btn-container {
  padding: 0.5rem 0;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
}

.bottom {
  border-bottom: 1px solid #ced4da;
}

.style-btn {
  margin: 0.5rem;
  cursor: pointer;
}

.style-btn:hover {
  font-weight: bold;
  transition: font-weight 0.3s ease-in-out;
}

.active-style {
  font-weight: bold;
}

.modify-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.modify-text-btn {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin: 0 0.5rem;
}

.modify-text-btn:hover {
  background: #fff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.save {
  border-color: #185493;
  background: #185493;
  color: #fff;
}

.save:hover {
  color: #185493;
}

.cancel {
  border-color: #6c757d;
  background: #6c757d;
  color: #fff;
}

.cancel:hover {
  color: #6c757d;
}

.edit {
  border-color: #185493;
  background: #185493;
  color: #fff;
}

.edit:hover {
  color: #185493;
}

.terms-link {
  color: #185493;
}

.terms-link:hover {
  font-weight: bold;
  transition: font-weight 0.3s ease-in-out;
}
